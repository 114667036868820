.top_select[data-v-44646f39] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.item_icon[data-v-44646f39] {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.item_icon i[data-v-44646f39] {
  display: inline-block;
  line-height: 13px;
  font-size: 13px;
  margin-right: 2px;
}
.item_icon p[data-v-44646f39] {
  display: inline-block;
  font-size: 13px;
  color: #333;
}
.text_width[data-v-44646f39] {
  width: 52px;
  display: inline-block;
}
.icon-tiaoguo1[data-v-44646f39] {
  color: #17a2b8;
}
.style_form .el-form-item[data-v-44646f39] {
  margin-bottom: 10px;
}
.icon-a-xinjianzidingyichicun21x1[data-v-44646f39] {
  font-size: 15px;
  margin-right: 2px;
}
.icon-jiantou-you[data-v-44646f39] {
  font-weight: 600;
}
.auto_content_size[data-v-44646f39] {
  width: 91%;
  margin-left: 10px;
}
.icon-weixintuisong[data-v-44646f39] {
  color: #15ba11;
  font-size: 13px;
  margin-left: 8px;
  cursor: pointer;
  position: relative;
  top: 2px;
}